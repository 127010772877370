<template>
  <div>
      <form class="d-flex" @submit.prevent>
      <vue-simple-suggest
        type="search"
        placeholder="Filter by edge"
        aria-label="Search"
        v-model="tag"
        :list="newList"
        :debounce="200"
        :controls="{
          selectionUp: [38, 33],
          selectionDown: [40, 34],
          select: [13, 36],
          showList: [40],
          hideList: [27, 35],
        }"
        :nullable-select="true"
        ref="searchNode"
        value-attribute="id"
        display-attribute="text"
        @suggestion-click="onSuggestClick"
      ></vue-simple-suggest>
    </form>
    <!-- <div>
      <li @click="pushIntoTags(item)" v-for="item in newList" :key="item">{{item}}</li>
    </div> -->
    <div>
      <div v-for="(item, index) in tags" :key="index" class="filter_item">
        <span>{{ item }}</span>
        <i
          @click="removeFilterItem(item)"
          class="bx bxs-message-x remove_filter"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';

export default {
  components: {
    VueSimpleSuggest,
  },

  watch: {
    tag(val) {
      this.newList = this.$store.state.g6_graph_data.edges
        .filter((edge) => edge.label.toLowerCase().includes(val.toLowerCase()))
        .map((edge) => edge.label);

      this.newList = Array.from(new Set(this.newList));
      this.newList = [...this.newList];
      if (val === '') {
        this.newList = [];
      }
    },

    tags(val) {
      this.$root.$emit('filterNodes', val);
    },
  },

  methods: {
    removeFilterItem(item) {
      this.tags = this.tags.filter((val) => val !== item);
      // this.tags.forEach((val, index) => {
      //   if (val === item) {
      //     this.tags.splice(index);
      //   }
      // });
      // this.tags.splice(this.tags.indexOf(item));
    },
    onSuggestClick(item) {
      if (!this.tags.includes(item)) {
        this.tags.push(item);
      }
    },
    pushTheWord() {
      this.tags.push(this.tag);
    },
    getCurrentList() {
      const newFilterList = this.$store.state.g6_graph_data.edges.map(
        (edge) => edge.label,
      );

      return newFilterList;
    },

    // onTagsChanged(val) {

    // },
  },

  data() {
    return {
      tag: '',
      tags: [],
      prevTags: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.filter_item {
  border: 0.0625em solid rgb(187, 187, 187);
  display: inline-flex;
  border-radius: 0.3125em;
  padding: 0.3125em;
  span {
    margin-right: 0.3125em;
  }
  .remove_filter {
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>
